import 'primeicons/primeicons.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import './index.css'
import React, { useEffect, useState } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import MT_log from './MT_log'
import PF_log from './PF_log'
import LB_log from './LB_log'
import ESS_log from './ESS_log'

export default () => {
  const [tab, setTab] = useState('ESS')
  const [activeIndex, setActiveIndex] = useState(1)

  const items = [
    { label: 'PF', icon: 'pi pi-fw pi-phone', index: 0 },
    { label: 'MT', icon: 'pi pi-fw pi-id-card', index: 1 },
    { label: 'LB', icon: 'pi pi-fw pi-list', index: 2 }
  ]

  const Inside = {
    PF: <PF_log />,
    MT: <MT_log />,
    LB: <LB_log />,
    ESS: <ESS_log />
  }

  const onTabChange = e => {
    setActiveIndex(e.value.index)
    setTab(e.value.label)
  }

  useEffect(() => {
    const elemMenu = document.querySelectorAll('li[class="p-tabmenuitem"] [class="p-menuitem-link"]')
    for (var i = 0; i < elemMenu.length; i++) {
      elemMenu[i].style.color = '#6c757d'
      elemMenu[i].style.borderColor = '#dee2e6'
    }

    let color = '#ee2181'

    if (tab === 'PF') {
      color = 'rgb(91, 181, 78)'
    }
    if (tab === 'MT') {
      color = '#50B5FF'
    }
    if (tab === 'LB') {
      color = 'rgb(255, 104, 104)'
    }
    try {
      document.querySelector('li[class="p-tabmenuitem p-highlight"] [class="p-menuitem-link"]').style.color = color
      document.querySelector('li[class="p-tabmenuitem p-highlight"] [class="p-menuitem-link"]').style.borderColor = color
    } catch (e) {}
  }, [tab])

  return (
    <div>
      {
        ((tab) => {
          if (tab === 'MT') {
            return (
              <div className='card'>
                <TabMenu model={items} activeIndex={activeIndex} onTabChange={onTabChange} />
              </div>
            )
          } else if (tab === 'ESS') {
            return <div />
          }
        })(tab)
      }
      {Inside[tab]}
    </div>
  )
}
