import { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
// import 'primereact/resources/primereact.min.css'
// import 'primeicons/primeicons.css'
import './index.css'

export default props => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const accountAdd = async () => {
    console.log({ username, password })
    return
    await fetch('/login', {
      method: 'POST',
      body: new URLSearchParams(`username=${username}&password=${password}`)
    }).then(r => r.json()).then(r => r)
  }

  return (
    <div className='login_box'>
      <form action='/login' method='post'>
        <span className='p-float-label login' style={{ border: 0 }}>
          <InputText
            style={{ top: 0 }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name='username'
          />
          <label htmlFor='user'>user</label>
        </span>
        <span className='p-float-label login login-pass' style={{ border: 0 }}>
          <InputText
            style={{ top: 0 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
          />
          <label htmlFor='password'>password</label>
        </span>
        <Button type='submit' label='Login' className='login_button' onClick={() => accountAdd()} />
      </form>
    </div>
  )
}
